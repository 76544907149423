<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Категории товара {{ itemCode }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            disabled
            outlined
          />
          <v-autocomplete
            v-model="form.data.categories"
            label="Категории"
            hint="Выберите категории, к которым относится данный товар"
            persistent-hint
            multiple
            chips
            :items="categoriesData"
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :color="item.isActive?null:'orange'"
                :input-value="selected"
              >
                <span class="pr-2">
                  {{ item.text }}
                </span>
                <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <!--          <v-btn :to="{name: 'item.view', params: { id }}">Отмена</v-btn>-->
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapState } from 'vuex'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemCategoryEditPage',
  props: {
    id: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          categories: []
        }
      }
    }
  },
  computed: {
    ...mapState('item_category_edit', ['item', 'categories', 'busy']),
    itemCode() {
      if (this.item.codeBs) {
        return this.item.code + ' (' + this.item.codeBs + ')'
      }

      return this.item.code
    },
    'categoriesData': function(state) {
      const activeCategories = state.categories
        .filter((o) => o.isActive)
        .map((o) => { return { text: o.name, value: o.id, isActive: o.isActive } })

      const blockedCategories = state.categories
        .filter((o) => !o.isActive)
        .map((o) => { return { text: o.name, value: o.id, isActive: o.isActive } })

      return [
        { header: 'Активные' },
        ...activeCategories,
        { divider: true },
        { header: 'Заблокированные' },
        ...blockedCategories
      ]
    }
  },
  created() {
    this.fetchData({ id: this.id })
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.categories = this.item.categories.map((c) => c.id)
      })
  },
  methods: {
    ...mapActions('item_category_edit', ['fetchData', 'updateItemCategories']),
    submitForm() {
      this.updateItemCategories({ categoryIds: this.form.data.categories })
        .then(() => this.$router.back())
        // .then(() => this.$router.push({ name: 'item.view', params: { id: this.id } }))
    },
    remove(item) {
      const index = this.form.data.categories.indexOf(item.id)

      if (index >= 0) {
        this.form.data.categories.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
